import React from "react";
import { Link } from "gatsby";

import SEO from "../components/seo";
import "../components/layout.css";

export default function NotFoundPage() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <SEO title="404: Not Found" />
      <div style={{ padding: 16 }}>
        <h1 className="ArticleTitle">
          Sorry, whatever you were looking for isn't here.
        </h1>
        <p>
          Head back <Link to="/">home</Link>.
        </p>
      </div>
    </div>
  );
}
